<template>
  <div ref="search" id="search" class="h-full w-full py-6 flex flex-col justify-between gap-4 lg:mr-0 print:hidden print:invisible">
    <div class="flex flex-col justify-between gap-4">
      <div class="flex justify-between">
        <h3>{{ locale == 'de' ? 'Suche' : 'Search' }}</h3>
        <button tabindex="-1" id="closeSearch" class="flex whitespace-nowrap items-center gap-1.5 px-2 md:px-4 lg:px-8 h-8 lg:h-12 rounded-full border-2 border-stw-border bg-stw-grey-dark w-fit text-white lg:hover:bg-stw-grey-light lg:hover:text-stw-grey-dark shadow-md lg:hover:shadow">
          {{ locale == 'de' ? 'Suche schliessen' : 'Close search' }}
        </button>
      </div>
      <div class="flex items-center gap-4 max-w-prose">
        <label for="searchinput" class="sr-only">{{ locale == 'de' ? 'Suchen' : 'Search' }}</label>
        <input id="searchinput" ref="searchInput" @input="startSearch" class="w-1/2 md:w-3/4 border border-dark p-2 rounded pr-4 flex-1" type="text" :placeholder="locale == 'de' ? 'Suchbegriff' : 'Search phrase'" v-model="searchPhrase" />
      </div>
    </div>
    <div class="w-full relative h-full overflow-y-auto">
      <div v-if="searching">{{ locale == 'en' ? 'Searching ...' : 'Suchen ...' }}</div>
      <div class="mt-2 lg:mt-4" v-else-if="results && results.length">
        <ul class="lg:text-lg flex flex-col gap-4">
          <li v-for="result in results" :key="result.id">
            <a class="result group w-fit flex flex-col" :href="`/${locale}/${result.uri}`" tabindex="2">
              <span class="flex flex-col md:flex-row flex-wrap md:gap-2">
                {{ result.title }}
                <span v-if="result.parents && typeof result.parents === 'object'">
                  <span class="opacity-60 text-sm whitespace-nowrap" v-for="par in Object.keys(result.parents)"> in {{ result.parents[par].content.title }}</span>
                </span>
              </span>
              <span class="w-0 lg:group-hover:w-full transition-all h-0.5 bg-stw-grey-dark"></span>
            </a>
          </li>
        </ul>
      </div>
      <div v-else>
        {{ locale == 'en' ? 'No results' : 'Keine Ergebnisse' }}
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'

  const props = defineProps({
    locale: {
      type: String,
      required: true,
    },
    baseurl: {
      type: String,
      required: true,
    },
    apitoken: {
      type: String,
      required: true,
    }
  });
  
  const search = ref('')
  const searchPhrase = ref('')
  const results = ref([])
  const searchInput = ref(null)
  const searching = ref(false)
 
  const startSearch = async () => {
    if (searchPhrase.value.length >= 3) {
      searching.value = true
      const queryObject = {
        q: searchPhrase.value,
        ln: props.locale
      }
      const response = await fetch(`${props.baseurl}/api/stwsearch`, {
        method: 'post', 
        headers: {
          'Content-Type':'application/json',
          'X-Language':`${props.locale}`,
          'Authorization': `Bearer ${props.apitoken}`,
        },
        body: JSON.stringify(queryObject)
      })
      const data = await response.json()
      results.value = data.results
      searching.value = false
    } else {
      results.value = []
    }
  }

</script>